<template>
  <div class="bg-white p-20 rounded">
    <div class="mb-20">
      <a-row :gutter="20" v-if="list.length">
        <a-col :span="6" v-for="(item, index) in list" :key="item.CompanyID">
          <div :class="{ 'mt-20': index > 3 }" class="relative">
            <div
              @click="doUnlinkKnowledge(item)"
              class="
                text-sm text-111
                font-medium
                absolute
                top-10
                right-10
                cursor-pointer
                z-10
              "
            >
              移除
            </div>
            <knowledge-card :dataSource="item"></knowledge-card>
          </div>
        </a-col>
      </a-row>
      <a-empty v-else></a-empty>
    </div>
    <div class="text-center" v-if="list.length">
      <a-pagination
        :pageSize="limit"
        :total="total"
        v-model="current"
        @change="doGetExpertKnowledge"
      ></a-pagination>
    </div>
  </div>
</template>

<script>
import { getExpertKnowledge } from "@/api/expert";
import { unlinkKnowledge } from "@/api/knowledge";
export default {
  data() {
    return {
      status: 1,
      list: [],
      limit: 12,
      total: 0,
      current: 1,
    };
  },
  computed: {
    expert_id() {
      return this.$store.state.userInfo.expert_id;
    },
  },
  created() {
    this.doGetExpertKnowledge();
  },
  methods: {
    doUnlinkKnowledge(item) {
      const del = this.unlinkKnowledge,
        id = item.id,
        name = item.name;
      this.$confirm({
        title: "知识移除",
        content: `您将把《${name}》从您的知识库中移出，是否移除`,
        okText: "移除",
        cancelText: "取消",
        okType: "danger",
        onOk: () => {
          del(id);
        },
      });
    },
    doGetExpertKnowledge() {
      const temp = {
        limit: this.limit,
        offset: this.limit * (this.current - 1),
      };
      this.getExpertKnowledge(this.expert_id,temp);
    },
    getExpertKnowledge(id,temp) {
      getExpertKnowledge(id,temp)
        .then(({ count, rows }) => {
          this.total = count;
          this.list = rows;
        })
        .catch(() => {
          this.$message.error("获取专家知识库失败");
        });
    },
    unlinkKnowledge(id) {
      unlinkKnowledge(id)
        .then(() => {
          this.$message.success("移除知识成功");
          this.doGetExpertKnowledge();
        })
        .catch(() => {
          this.$message.error("移除知识失败");
        });
    },
  },
};
</script>