import { request, proRequest } from '@/utils/request.js'
import { transformKnowledge } from '@/utils/transform.js'

// 专家是否关联知识库
export function connectKnowledge(id) {
    return request.get(`knowledges/${id}/connected`)
}

//专家关联知识库
export function linkKnowledge(id) {
    return request.post(`/knowledges/${id}/connect`)
}

//专家取消关联知识库
export function unlinkKnowledge(id) {
    return request.post(`/knowledges/${id}/disconnect`)
}

// 获取知识库列表
export function getKnowledgeList(params) {
    return request.get(`/knowledges`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformKnowledge(row))
        return Promise.resolve(temp)
    })
}

// 获取推荐知识库列表
export function getRecommonendKnowledgeList(params) {
    return request.get(`/recommend/knowledges`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformKnowledge(row))
        return Promise.resolve(temp)
    })
}

// 获取知识库详情
export function getKnowledgeDetail(id) {
    return request.get(`/knowledges/${id}`).then(({ data }) => {
        const temp = transformKnowledge(data)
        return Promise.resolve(temp)
    })
}

// 后台获取知识库列表
export function getProKnowledgeList(params) {
    return proRequest.get(`/knowledges`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformKnowledge(row))
        return Promise.resolve(temp)
    })
}

// 后台创建知识库
export function createProKnowledge(data) {
    return proRequest.post(`/knowledges`, data)
}

// 后台删除知识库
export function deleteProKnowledge(id) {
    return proRequest.delete(`/knowledges/${id}`)
}

// 后台获取知识库详情
export function getProKnowledgeDetail(id) {
    return proRequest.get(`/knowledges/${id}`).then(({ data = {} }) => {
        return Promise.resolve(transformKnowledge(data))
    })
}

// 后台更新知识库详情
export function putProKnowledge(id, data) {
    return proRequest.put(`/knowledges/${id}`, data)
}

// 后台设置知识库可见性
export function setProKnowledgeVisible(id, data) {
    return proRequest.post(`/knowledges/${id}/visible`, data)
}

// 后台获取推荐知识库列表
export function getProRecommonendKnowledgeList(params) {
    return proRequest.get(`/recommend/knowledges`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformKnowledge(row))
        return Promise.resolve(temp)
    })
}
